import React, { Component } from "react";

class Registry extends Component {

  render() {
    return (
      <div className="partial text-center">
          <div className="details col-10 offset-1 col-sm-8 offset-sm-2">
            <div className="copy">
              <p>
              The photographers will be live streaming both the reception & the wedding.</p>
              <p>
                Please refer back to this page on the date of the event for the live stream.
              </p>
            </div>

        </div>
      </div>
    );
  }
}
 
export default Registry;